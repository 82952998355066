@import "functions/functions";
@import "mixins/mixins";
@import "colors";
@import "palette";
@import "../../vendor/animate.scss/animate.scss";
@import "animations";
@import "typography";
@import "height-width";
@import "box-shadow";
@import "layouts/layouts";
@import "components/components";
@import "header";
@import "header-top";
@import "footer";
@import "utilities/utilities";
@import "views/views";
@import "newLayout/newLayout";

html {
  font-size: 16px;
}

body {
  letter-spacing: 0.3px;
  line-height: 1.6;
  background: $background;
  overflow-x: hidden;
  overflow-y: scroll;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html,
body {
  &[dir="rtl"],
  &[dir="ltr"] {
    unicode-bidi: embed;
  }
}

bdo[dir="rtl"] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir="ltr"] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

blockquote {
  border-left: 2px solid $gray-200;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.o-hidden {
  overflow: hidden;
}

.separator-breadcrumb {
  margin-bottom: 2rem;
}

@for $i from 1 through 5 {
  .line-height-#{$i} {
    line-height: #{$i};
  }
}

.app-inro-circle {
  text-align: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 150px);
  .big-bubble {
    height: 280px;
    width: 280px;
    margin: 0 auto 20px;
    text-align: center;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 108px;
      color: #ffffff;
    }
  }
}

.loadscreen {
  text-align: center;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  height: 100vh;
  background: #ffffff;
  z-index: 999;
  .loader {
    position: absolute;
    top: calc(50vh - 50px);
    left: 0;
    right: 0;
    margin: auto;
  }
  .logo {
    display: inline-block !important;
    width: 80px;
    height: 80px;
  }
}

.img-preview {
  overflow: hidden;
  float: left;
  background-color: $gray-200;
  width: 100%;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.preview-lg {
  width: 200px;
  height: 150px;
}

.preview-md {
  width: 150px;
  height: 120px;
}

.preview-sm {
  width: 100px;
  height: 75px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

@media (max-width: 576px) {
  .app-inro-circle {
    .big-bubble {
      width: 220px;
      height: 220px;
    }
  }
  button.close {
    float: right;
    font-size: 1.2195rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 5px;
  }
}

[dir="rtl"] {
  .rtl-ps-none {
    .ps__rail-x,
    .ps__rail-y {
      display: none;
    }
  }
}


//tables
.hv-align .table thead tr th {
  vertical-align: top;
}
.overflow-auto-table {
  overflow: auto !important;
  padding-bottom: 20px;
}

.hv-align {
  .filter.date-filter {
    label {
      width: 80%;
      &.filter-label{
        flex: 0 0 20%;
        min-width: 40px;
      }
    }
  }
}



@media all and (max-width: 1199px) {
  .overflow-auto-table {
    overflow: auto !important;
  }
  .react-bootstrap-table-pagination {
    margin-bottom: 20px;
  }
}
.overflow-auto-table .react-bootstrap-table table {
  table-layout: auto;
}
th .order-4:after {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

@media all and (min-width: 768px) {
  .card-title-inline-block {
    .simple-card-title {
      display: inline-block;
    }
  }
}

@media all and (max-width: 767px) {
  .card-title-inline-block {
    .margin-bottom {
      margin-bottom: 20px;
    }
  }
}

.blink-form {
  -webkit-animation-name: flash-animation;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 0.3s;

  animation-name: flash-animation;
  animation-iteration-count: 2;
  animation-duration: 0.3s;
}

@-webkit-keyframes flash-animation {
  0% {
    background-color: rgba(0,255,0, 0.1);
  }
  50% {
    background-color: rgba(0,255,0, 0.2);
  }
  100% {
    background-color: rgba(0,255,0, 0.1);
  }
}

@keyframes flash-animation {
  0% {
    background-color: rgba(0,255,0, 0.1);
  }
  50% {
    background-color: rgba(0,255,0, 0.3);
  }
  100% {
    background-color: rgba(0,255,0, 0.1);
  }
}

//table
.min-width-100 {
  min-width: 100px;
}
.min-width-120 {
  min-width: 120px;
}
.min-width-165 {
  min-width: 165px;
}
.min-width-200 {
  min-width: 200px;
}
.max-width-55 {
  max-width: 55px;
}
.max-width-200 {
  max-width: 200px;
}
.max-height-200 {
  max-height: 200px;
}

.z-9 {
  z-index: 9;
}
.z-5 {
  z-index: 5;
}


.parent-child-container {
  display: none;
}

.parent-child-container.active-hierarchy {
  display: block;
}

.max-560 {
  max-width: 560px;
}

.active-class > p {
  color: #111!important;
  font-weight: 600;
  text-decoration: underline;
}

.start-from-here {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
}

.lazy-image {
  opacity: 0;
}

.lazy-image-loaded {
  opacity: 1;
  transition: opacity .15s;
}

.lazy-image-loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.react-datepicker__navigation-icon {
  top: 7px
}