.containerWrap {
  background-size: cover;
  min-height: 100vh;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url('/assets/images/IGL_warehouse.jpg');
    background-size: cover;
    opacity: .4;
    content: "";
    z-index: -1;
    min-height: 100vh;
  }
}

.containerWrap .imgWrap {
  height: 180px;
}

.containerWrap img {
  max-height: 100%;
}

.containerWrap .h100 {
  min-height: 100%;
}