.nav-tabs {
    border: 0;
    .nav-item {
        .nav-link {
            border: 0;
            padding: 1rem;
            &:not(.disabled) {
                color: inherit;
            }
            &.active {
                border-bottom: 2px solid $primary;
                background: rgba($color: $primary, $alpha: .1);
            }
        }
        .dropdown-toggle {
            &:after {
                position: absolute;
                top: calc(50% - 2px);
                right: 6px !important;
            }
        }
    }
}

.tab-content {
    padding: 1rem;
}

ngb-tabset.p-0 {
    .tab-content {
        padding: 1rem 0;
    }
}

.product-document-tab img {
    max-width: 150px;
    max-height: 150px;
    margin: auto;
}

@media all and (max-width: 575px) {
    .nav-tabs {
        padding-right: 10px;
        .nav-link {
            border: 1px solid #dee2e6;
            margin: 0;
            &.active {
                border: 1px solid #003473;
            }
        }
    }
}

.supplier-order-tabs {
    .tab-content {
        border: 1px solid #dee2e6;
    }

    .table-layout-custom {
        table-layout: fixed;
        width: 100%;
        min-width: 800px;
    }

    .table-layout-custom th,
    .table-layout-custom td {
        word-break: break-word;
        vertical-align: middle;
    }

    /* Fixed width for first column */
    .col-fixed {
        width: 60px;
    }

    /* Max width for image column */
    .col-image {
        max-width: 125px;
        width: 125px;
    }

    .col-image img {
        max-width: 100%;
        height: auto;
    }

    @media print {
        .tab-content {
            border: none !important;
            padding: 0 !important;
            background: transparent !important;
        }

        .tab-content > .tab-pane {
            display: block !important;  /* Show all tab panes */
            opacity: 1 !important;
            visibility: visible !important;
            position: static !important;
            border: none !important;
        }

        .nav-tabs {
            display: none !important;  /* Hide tab headers */
        }

        h5.d-print-block {
            margin-top: 2rem;
            font-weight: bold;
            padding-bottom: 0.5rem;
        }

        .card,
        .shadow,
        .rounded,
        .border,
        .tab-content {
            box-shadow: none !important;
            border: none !important;
            border-radius: 0 !important;
        }

        .table-layout-custom {
            table-layout: fixed !important;
            width: 100% !important;
        }

        .col-fixed {
            width: 60px !important;
        }

        .col-image {
            width: 125px !important;
            max-width: 125px !important;
        }

        .print-page-break-avoid {
            break-inside: avoid;
            page-break-inside: avoid;
        }

        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid;
            break-inside: avoid;
        }

        thead {
            display: table-header-group; /* Ensures headers repeat if breaking is allowed */
        }

        tfoot {
            display: table-footer-group;
        }

        .break-before {
            page-break-before: always;
        }

        .break-after {
            page-break-after: always;
        }
    }
}
