#calendar {
    float: right;
    width: 100%;
}

#external-events h4 {
    font-size: 16px;
    margin-top: 0;
    padding-top: 1em;
}

#external-events .fc-event {
    margin: 2px 0;
    cursor: move;
}

.create_event_wrap p {
    margin: 1.5em 0;
    font-size: 11px;
    color: #666;
}

.create_event_wrap p input {
    margin: 0;
    vertical-align: middle;
}

.fc-event {
    position: relative;
    display: block;
    font-size: .85em;
    line-height: 1.3;
    border-radius: 3px;
    border: 0px solid $primary !important;
}

a.fc-day-grid-event {
    background: $primary;
    padding: 5px;
}

th.fc-day-header {
    border-bottom-width: 2px;
    padding: 10px 0px;
    display: table-cell;
    background: #F5F5F5;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

td.fc-head-container {
    padding: 0px !important;
}

.fc-toolbar h2 {
    margin: 0;
    font-weight: bold;
}

span.fa {
    font-family: 'iconsmind' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

span.fa.fa-chevron-left:before {
    content: "\f077";
}

span.fa.fa-chevron-right:before {
    content: "\f07d";
}

.product-stock-calendar {
    position: relative;
}

.product-stock-calendar .fc-daygrid-day:hover {
    cursor: pointer;
}

.product-stock-calendar .fc-day-today {
    background-color: inherit !important;
}

.product-stock-calendar .fc-tooltip {
    width: 130px;
    position: absolute;
    z-index: 100;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px; /* Increase the border-radius for a balloon effect */
    padding: 8px; /* Increase the padding for a balloon effect */
    opacity: 0;
    transition: opacity 0.3s;
}

.product-stock-calendar .fc-tooltip::before {
    content: "";
    position: absolute;
    bottom: -20px; /* Distance of the arrow from the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 10px; /* Size of the arrow */
    border-style: solid;
    border-color: #000 transparent transparent transparent; /* Color of the arrow */
}

.product-stock-calendar .fc-tooltip.active {
    opacity: 1;
}

.product-stock-calendar .fc-daygrid-day:hover .fc-tooltip,
.product-stock-calendar .fc-daygrid-day:focus .fc-tooltip {
    opacity: 1;
}

//.css-tooltip {
//    position: relative;
//}
//.css-tooltip:hover:after {
//    content: attr(data-tooltip);
//    background: #000;
//    padding: 5px;
//    border-radius: 3px;
//    display: inline-block;
//    position: absolute;
//    transform: translate(-50%, -100%);
//    margin: 0 auto;
//    color: #fff;
//    min-width: 100px;
//    min-width: 150px;
//    top: -5px;
//    left: 50%;
//    text-align: center;
//    font-size :0.825rem;
//}
//
//.css-tooltip:hover:before,
//.css-tooltip:focus:before{
//    top: -5px;
//    left: 50%;
//    border: solid transparent;
//    content: " ";
//    height: 0;
//    width: 0;
//    position: absolute;
//    pointer-events: none;
//    border-color: rgba(0, 0, 0, 0);
//    border-top-color: #000;
//    border-width: 5px;
//    margin-left: -5px;
//    transform: translate(0, 0px);
//}