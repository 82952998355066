.quill.ql-error {
   .ql-toolbar {
      border-top: 1px solid #e22726;
      border-left: 1px solid #e22726;
      border-right: 1px solid #e22726;
   }
   .ql-container {
      border-left: 1px solid #e22726;
      border-right: 1px solid #e22726;
      border-bottom: 1px solid #e22726;
   }
}

.ul-product-detail__features .ql-editor{
    min-height: auto;
}
.ul-product-detail__features ul li{
   list-style: none;
   margin: 8px 0;
}

.carousel-root .carousel {
   margin: 20px 20px 0 0;
   &:hover button{
      background: rgba(0,0,0,0.2);
   }
   button.control-arrow {
      max-height: 55px;
      top: 50%;
      transform: translateY(-50%);
   }
   .slider-wrapper {
      .slider .slide {
         display: flex;
         justify-content: center;
         align-items: center;
         height: 600px;
         @media all and (max-width: 991px) {
            height: 300px;
         }
      }
      .slider .slide img {
         width: auto;
         max-width: 100%;
         height: auto;
         max-height: 100%;
      }
   }
   .control-dots {
      .dot {
         background-color: $primary;
         opacity: .6;
      }
   }
   .thumbs-wrapper{
      margin: 0;
      .thumb {
         height: 120px;
         position: relative;
         border: 1px solid #ccc;
      }
      .thumb img {
         position: absolute;
         top: 50%;
         left: 50%;
         -webkit-transform: translate(-50%, -50%);
         -moz-transform: translate(-50%, -50%);
         -ms-transform: translate(-50%, -50%);
         -o-transform: translate(-50%, -50%);
         transform: translate(-50%, -50%);
         max-height: 100%;
         width: auto;
      }
      .thumb.selected,
      .thumb:hover {
         border: 2px solid $primary;
      }
      .control-arrow {
         background-color: #0C0C3C;
      }
      .thumbs {
         padding-left: 0;
      }
   }
}

.pr-images-spinner {
   position: absolute;
   top: 40%;
   left: 50%;
   transform: translate(-50%,-50%);
   display: inline-block;
}



// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
   .w-lg-100 {
      width: 100%;
   }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}