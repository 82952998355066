.qty-field {
  max-width: 48px;
  height: 33px;
  padding-left: 5px;
}

.stock-text {
  color: #f2aa00;
  font-weight: bold;
}

.product-cart .card-body a {
  color: $foreground
}
.card-body .product-name {
  font-size: 1.4em;
}

.card-body .product-name p:empty::before {
  content: "";
  display: inline-block;
}

.product-cart .thumb-image {
  padding: 20px;
  height: 260px;
  .card-image-top {
    height: 100%;
  }
  .card-image-top a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-image {
    max-height: 100%;
  }
}

@media (min-width: 1520px) {
  .col-large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.product-cart .item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media all and (max-width: 767px){
  .product-cart .item-title {
    white-space: normal;
    max-width: inherit;
  }
}

@media all and (max-width: 575px) {
  .product-cart .thumb-image {
    padding: 20px;
    //height: auto;
  }
  .w-sm-100 {
    width: 100%;
  }
}

//cart
.product-cart-image {
  width: 100px;
  height: 100px;
  img {
    object-fit: contain;
  }
}

.table-loading-overlay {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #ffffff;
  opacity: 0.5;
  border-radius: 10px;
}