[role="tab"] {
    .btn {
        width: 100%;
        text-align: left;
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.accordion {
    &> .card {
        overflow: hidden;
    }
}

.overflow-visible {
    overflow: visible!important;
}
.search-bundle-form {
    height: auto;
    min-height: auto;
}
.mh-150 {
    min-height: 150px;
}

.search-tags {
    margin-top: -25px;
    max-width: 391px;
    max-height: 122px;
    overflow: auto;
    z-index: 1;
}